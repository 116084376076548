import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Footer } from "../components/footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { AnchorLink } from "gatsby-plugin-anchor-links";


// import Header from "../components/header";
// markup
const StudioPage = ({ data }) => {
  const [info = null] = data?.allSanityBusinessProfile?.nodes;
  const services = info?.services || [];
  const partnerList = info?.partnerList || [];
  const partnersPic = info?.partnersPic || [];

  const handleDragStart = (e) => e.preventDefault();

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const picsMapped = partnersPic.map((partner, index) => {
    return (
      <div class="flex justify-center align-items-center 2xl:h-[400px] xl:h-[400px] lg:h-[300px] h-[300px]  ">
        
          <img
            src={partner.asset.url}
             style={{objectFit:"contain"}}
            alt={"logo " + index}
             class="2xl:p-20 xl:p-20 lg:p-20 p-10"
            onDragStart={handleDragStart}
          />
     
      </div>
    );
  });

  return (
    <div>
      <Helmet>
        <title>0039 STUDIO - Studio</title>
      </Helmet>
      
      <div id="header" name="header" class="lg:min-h-screen  bg-white-100 flex lg:flex-row flex-col lg:justify-center ">
        <div class="lg:min-h-screen lg:w-5/12 lg:min-w-0 min-w-full bg-white 2xl:pl-40 xl:pl-40 lg:pl-20 lg:pt-20 flex flex-col z-10">
        <div class="lg:pl-0 lg:absolute lg:top-20 lg:pt-0 pt-5 pb-5 lg:pb-10 flex justify-center">
              <AnchorLink to="/#menu" stripHash>
              <StaticImage
                src="../images/logo.png"
                height="100"
                placeholder="tracedSVG"
                quality="100"
                alt="head-logo"
              ></StaticImage>
              </AnchorLink>
          </div>
          <div class="text-black opacity-30 mt-auto pb-20 lg:block hidden">
            <ul class="uppercase font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl ">
              <Link to="/work">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  WORK
                </li>
              </Link>
              <Link to="/about">
                {" "}
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  ABOUT
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div class="min-h-screen lg:w-7/12 lg:min-w-0 min-w-full bg-black lg:flex lg:bg-opacity-30 2xl:pl-40 xl:pl-40 lg:pl-20  lg:text-6xl md:text-5xl text-4xl  bg-studio  flex flex-row lg:justify-start  justify-center lg:flex hidden">
          <div class="mt-auto pb-20 lg:flex hidden">
             <div class="uppercase font-bold text-7xl  cursor-pointer flex-column">
              {/* <div>Click</div> <div>here</div> <div>to design</div>{" "}
              <div>your story</div> */}
            </div> 
          </div>

          <div class="top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:absolute lg:top-40 lg:right-20 lg:text-6xl md:text-5xl text-4xl flex flex-col justify-center ">
            <div class="transform -rotate-90 lg:block hidden">
              <div>STUDIO</div>
            </div>
            <div class="lg:hidden block text-white text-center min-w-full flex flex-col justify-center uppercase">
            <div>Click</div> <div>here</div> <div>to design</div>{" "}
              <div>your story</div>
            </div>
          </div>
        </div>
      </div>

      

      <div class=" bg-white-100 flex-col-remargin-setts 2xl:pr-40 xl:pr-40 lg:pr-20 pr-10 2xl:pl-40 xl:pl-40 lg:pl-20 pl-10">
        <div class="uppercase lg:text-7xl text-5xl font-bold margin-setts  lg:mt-60 mt-10">
          <span>BUSINESS PROFILE</span>
        </div>
        <div class=" mt-20 flex-1 lg:text-3xl text-studio-services font-bold leading-normal">
          <ul>
            {services.map((service, index) => {
              //write the service name with the | as separator
              const listElement = <li class="lg:inline-block block">{service}</li>;
              const separator = <span class="lg:inline-block hidden pl-2 pr-2"> | </span>;
              if (index !== services.length - 1) {
                return [listElement, separator];
              }
              return [listElement];
            })}
          </ul>
      <hr class="lg:mt-80 mt-20  text-gray-400"></hr>

        </div>

      </div>

      <div class="bg-white-100 flex-col-re 2xl:pl-40 xl:pl-40 lg:pl-20 pl-10 lg:mt-60 mt-20 xl:pr-40 lg:pr-20 pr-10 lg:mb-80 mb-20 ">
        <div class="uppercase lg:text-7xl text-5xl font-bold margin-setts  ">
          <span>PARTNERS</span>
        </div>
        <div class="  mt-20 flex-1 text-3xl font-bold flex items-center">
          <AliceCarousel
            mouseTracking={false}
            items={picsMapped}
            responsive={responsive}
            autoPlay
            autoPlayControls={false}
            autoPlayStrategy="none"
            autoPlayInterval={100}
            animationDuration={5000}
            animationType="slide"
            infinite
            disableDotsControls
            disableButtonsControls
          />
        </div>
        <hr class="lg:mt-80 mt-20 text-gray-400"></hr>
        <div class="lg:mt-20 mt-10 flex-1 text-3xl font-bold flex items-center">
          <div class="lg:mt-40 mt-10 font-medium leading-normal 2xl:text-4xl xl:text-3xl lg:text-2xl text-studio-services">
            <div>{partnerList.join(", ")}</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default StudioPage;
export const pageQuery = graphql`
  {
    allSanityBusinessProfile {
      nodes {
        services
        partnersPic {
          asset {
            url
          }
        }
        partnerList
      }
    }
  }
`;
